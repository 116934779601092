import React from 'react'
import Layout from '../layouts/Layout'
import { Container, Flex, Grid } from 'theme-ui'
import { Link } from 'gatsby'
import { Icon } from '../components/Icon'
import { StaticImage } from 'gatsby-plugin-image'

export default function Home() {
  return (
    <Layout theme="light">
      <Flex
        sx={{
          backgroundImage: [
            'url(../images/HeroMobileHome.jpg)',
            null,
            'url(../images/home_hero_bg.jpg)',
          ],
          backgroundSize: ['cover'],
          backgroundPosition: [
            'bottom',
            'bottom',
            ' 70% 0px',
            null,
            ' 70% 0px',
            'center',
          ],
          width: '100%',
          height: [845, 945, 600, null, 600, 700],
          justifyContent: 'center',
          alignItems: ['center'],
        }}
      >
        <div
          sx={{
            maxWidth: ['100%', null, null, 1100],
            width: '100%',
            px: 4,
          }}
        >
          <h1
            sx={{
              width: ['100%', null, '488px'],
              top: [-100, -180, -100, 0, 0],
              position: 'relative',
              color: ['black', null, null, 'black'],
              textAlign: ['center', null, null, 'left'],
            }}
          >
            Credit Solutions for Everyday Consumers Across North America
          </h1>
        </div>
      </Flex>
      <Container
        sx={{
          justifyContent: 'center',
        }}
      >
        <p
          sx={{
            textAlign: 'center',
            maxWidth: [350, null, null, 700],
          }}
        >
          At Attain Finance, we put our customers at the heart of everything we
          do. As a trusted financial resource and partner, we are committed to
          understanding the unique challenges and goals facing everyday
          consumers. We offer transparent, personalized solutions to empower
          people to move forward.{' '}
        </p>
      </Container>
      <section
        sx={{
          background: 'url(../images/gray-shape.jpg)',
          backgroundPosition: 'bottom',
          width: '100%',
          height: 700,
        }}
      >
        <Container
          sx={{
            flexDirection: 'column',
          }}
        >
          <h2>What We Offer</h2>
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: ['column', null, null, 'row'],
            }}
          >
            <p
              sx={{
                maxWidth: 700,
                textAlign: ['center', null, null, 'left'],
                pb: [4, null, null, 0],
              }}
            >
              Personal Loans. Our product lines consist of various secured and
              unsecured, installment loans and lines of credit up to $10,000 or
              more. We aim to continue evolving what we offer and how we offer
              it.{' '}
            </p>
            <Link
              to="/credit-solutions"
              sx={{
                variant: 'buttons.primary',
                mb: [4, null, null, 0],
              }}
            >
              Explore Credit Solutions
            </Link>
            <p
              sx={{
                display: ['block', null, null, 'none'],
              }}
            >
              Consumer Credit Access
            </p>
          </Flex>
          <Grid
            sx={{
              gridTemplateColumns: [
                '3fr repeat(2, 2fr)',
                null,
                null,
                '3fr repeat(2, 1fr)',
              ],
              height: [50, null, null, 'auto'],
            }}
          >
            <p
              sx={{
                visibility: ['hidden', null, null, 'visible'],
              }}
            >
              Consumer Credit Access
            </p>
            <p
              sx={{
                fontSize: [13, null, null, 16],
                justifySelf: 'center',
                textAlign: ['left'],
              }}
            >
              United States
            </p>
            <p
              sx={{
                fontSize: [13, null, null, 16],
                justifySelf: 'center',
              }}
            >
              Canada
            </p>
          </Grid>
          <Grid
            sx={{
              gridTemplateColumns: [
                '3fr repeat(2, 2fr)',
                null,
                null,
                '3fr repeat(2, 1fr)',
              ],
              alignItems: 'center',
              bg: 'white',
              border: '#B1B1B1 1px solid',
              borderRadius: 4,
            }}
          >
            <p
              sx={{
                fontSize: [14, null, null, 25],
                pl: [3, null, null, 4],
                textAlign: ['left'],
              }}
            >
              Branch-based{' '}
              <br sx={{ display: ['block', null, null, 'none'] }} /> Lending
            </p>
            <Icon
              name="check-mark"
              size={30}
              sx={{ ml: [0, null, null, 4], justifySelf: 'center' }}
            />
            <Icon
              name="check-mark"
              size={30}
              sx={{ ml: [0, null, null, 18], justifySelf: 'center' }}
            />
          </Grid>
          <Grid
            sx={{
              gridTemplateColumns: [
                '3fr repeat(2, 2fr)',
                null,
                null,
                '3fr repeat(2, 1fr)',
              ],
              alignItems: 'center',
              bg: 'white',
              border: '#B1B1B1 1px solid',
              borderRadius: 4,
              mt: 4,
            }}
          >
            <p
              sx={{
                fontSize: [14, null, null, 25],
                pl: [3, null, null, 4],
                textAlign: ['left'],
              }}
            >
              Digital Lending
            </p>
            <Icon
              name="check-mark"
              size={30}
              sx={{ ml: 4, visibility: 'hidden' }}
            />
            <Icon
              name="check-mark"
              size={30}
              sx={{ ml: [0, null, null, 18], justifySelf: 'center' }}
            />
          </Grid>
        </Container>
      </section>
      <section>
        <Container
          sx={{
            justifyContent: 'center',
            flexDirection: ['column', null, null, 'row'],
          }}
        >
          <StaticImage
            src="../images/OurApproachImage.jpg"
            alt="Woman and client"
            sx={{
              alignSelf: 'center',
              width: [250, null, null, 375],
              mb: [3, null, null, 0],
            }}
          />
          <Flex
            sx={{
              maxWidth: ['100%', null, null, 490],
              mx: [2, null, null, 4],
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Our Approach</h2>
            <p
              sx={{
                px: [3, null, null, 2],
                textAlign: ['center', null, null, 'left'],
              }}
            >
              The philosophy behind Attain Finance is simple. Provide the
              products our customers are looking for in a manner that
              demonstrates a high level of respect and service, treat our
              employees with discernible appreciation, and be as efficient as
              possible in the name of customer convenience.{' '}
            </p>
            <Link
              to="/about"
              sx={{
                variant: 'buttons.primary',
                width: 278,
                mt: 3,
              }}
            >
              Discover More About Us
            </Link>
          </Flex>
        </Container>
      </section>
      <section
        sx={{
          background: [
            'url(../images/mobile_world_shape.png)',
            null,
            null,
            'url(../images/shape_w_world.jpg)',
          ],
          backgroundSize: ['cover', null, null, null, 'cover', 'contain'],
          backgroundPosition: ['bottom', null, null, 'bottom', 'center'],
          backgroundRepeat: ['no-repeat', null, null, 'no-repeat'],
          width: '100%',
          height: [531, null, null, 500, 430],
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Container
          sx={{
            color: 'white',
            flexDirection: 'column',
            justifyContent: ['flex-start', null, null, 'center'],
            pt: [5, null, null, 3],
          }}
        >
          <h2>Where We Are</h2>
          <p
            sx={{
              maxWidth: ['100%', null, null, 562],
              textAlign: ['center', null, null, 'left'],
            }}
          >
            We are located in the US and Canada, offering a variety of personal
            loan products that meet state/provincial and federal regulations as
            well as the needs of our customers.
          </p>

          <StaticImage
            src="../images/world-mobile.png"
            alt="World Map"
            placeholder="none"
            layout="fixed"
            sx={{
              mt: 4,
              alignSelf: 'center',
              display: ['block', null, null, 'none'],
            }}
          />
        </Container>
      </section>
      <section>
        <Container
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h2
            sx={{
              m: 0,
            }}
          >
            Unlock Your Potential
          </h2>
          <p
            sx={{
              color: '#2096D3',
              pb: 4,
              fontSize: [18, null, null, 16],
              textAlign: 'center',
            }}
          >
            Apply for a career with
            <br sx={{ display: ['block', null, null, 'none'] }} /> us today
          </p>
          <Link
            to="/careers"
            sx={{
              variant: 'buttons.secondary',
              width: 225,
            }}
          >
            Visit Careers Page
          </Link>
        </Container>
      </section>
    </Layout>
  )
}
